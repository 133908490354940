<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <head-center></head-center>
            </nav>
        </div>

        <!--      内容          -->
        <div class="center-content">
            <div class="iq-search-bar">
                <el-form :model="searchForm" :inline="true">
                    <el-form-item label="商品分类">
                        <el-cascader v-model="searchForm.categoryId" :options="CategoryData"
                            :props="{ checkStrictly: true, value: 'id', label: 'name', children: 'children' }"
                            size="small" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                            @keyup.enter.native="search"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="search()">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content-box">
                <el-row type="flex" justify="space-between" :gutter="20" class="el-col">
                    <el-col :span="12" v-for="(item,index) in ManagerList" :key="index" >
                        <el-card>
                            <div class="item-div flex-space-between" @click="toDetail(item)">
                                <div class="img-box"><img :src="item.showCommodityCover[0]"></div>
                                <div class="right-div">
                                    <div class="t1">{{item.commodityName}}</div>
                                    <div>市场价：¥ {{item.guidancePrice }}
                                    </div>
                                    <div>更多优惠联系请联系客服<span class="price">¥ {{item.platformPrice}}</span></div>
                                     <div>联系客服：<span class="t2">{{item.agentStore.customerPhone}}</span></div>
                                    <div>产地：<span class="t3">{{item.origin}}</span><i class="el-icon-circle-check t4"></i></div>
                                    <div class="t5">{{item.manufacturer}}</div>
                                    
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
                               <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="CommodityTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </div>

    </div>
</template>

<script>

import countTo from 'vue-count-to';
import { ShoppingAgent } from '../../../components/ShoppingAgent/commodity';
export default {
    name: "square",
    computed: {
        headers() {
            return {
                "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
            }
        }
    },
    components: {
        countTo
    },
    data() {
         var ShoppingAgentTemop = new ShoppingAgent(this.TokenClient, this.Services.Shopping);
        return {
            value1: 5,
             ShoppingAgentDomain: ShoppingAgentTemop,
             ManagerList:[],
              CategoryData: [],
            searchForm: {
                state: ' ',
                categoryId: '',
                keyWord: '',
            },
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            CommodityTotal: 1,
        }
    },
    mounted() {
        this.getManagerListData()
        this.GetCategory()
    },
    methods: {
        getManagerListData() {
            var _this = this;
            _this.ManagerList = [];
            var item = _this.searchForm;
            var categoryId = item.categoryId[item.categoryId.length - 1]
            _this.ShoppingAgentDomain.getManagerList(item.keyWord, categoryId, _this.PageIndex, function (data) {
            
                _this.PageIndex = data.data.pageIndex;
                _this.PageSize = data.data.pageSize;
                _this.PageTotal = data.data.pageCount;
                _this.CommodityTotal = data.data.dataTotal;
                for (let i=0; i<data.data.results.length; i++) {
                    let paramslist = []
                    for (let j=0; j< JSON.parse(data.data.results[i].commodityCover).length; j++) {
                        paramslist.push(_this.Services.Shopping + JSON.parse(data.data.results[i].commodityCover)[j])
                    }
                      data.data.results[i].showCommodityCover = paramslist
                }
                _this.ManagerList = data.data.results
                console.log(_this.ManagerList)
            },
                function (error) {
                    console.log(error);
                }
            )
        },
        search() {
            this.PageIndex = 1;
            this.getManagerListData();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getManagerListData();
        },
        toDetail(item) {
            var _this = this;
            _this.$router.push(
                {
                    path: '/squareDetail',
                    query: { id: item.id }
                }
            );
        },
        GetCategory() {
            var _this = this;
            _this.ShoppingAgentDomain.Categories(
                function (data) {
                    // _this.CategoryData = data.data;
                    if (data.data) {
                        data.data.forEach(element => {
                            if (element.name != "处方药") {
                                _this.CategoryData.push(element)
                            }
                        });
                    }
                },
                function (err) {
                    console.log(err);
                });
        },
    }
}
</script>

<style scoped lang="scss">
.el-col {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

/*content*/
.center-content {
    height: calc(100vh - 43px);
    overflow-y: auto;
    padding: 100px 15px 0;

    .content-box ::v-deep .el-row {
        margin-bottom: 20px;
    }
    
    .content-box ::v-deep .el-card {
        cursor: pointer;
    }

    .item-div {
        cursor: pointer;
        .img-box {
            width: 30%;
            // border-radius: 10px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
                transition:all 0.3s;
            }
            img:hover{ transform:scale(1.1)}
        }

        .right-div {
            width: 65%;
            color: #828690;

            .t1 {
                font-size: 15px;
                font-weight: bold;
                color: #000000;
            }

            .t2 {
                text-decoration: underline // font-size: 12px;
            }

            .t4 {
                color: #2BC155 !important;
                margin-left: 5px;
            }

            .price {
                font-size: 18px;
                color: var(--baseColor);
                font-weight: bold;
                margin-left: 10px;
            }

            .t3 {
                font-size: 12px;
                color: #B1B1B1;
            }

            .t5 {
                margin-top: 10px;
            }
        }
    }

    // .item-div:nth-child(2n) {
    //     margin-right: 0;
    // }
}

.elCard {
    box-shadow: none;
    margin-top: 25px;
}</style>
