let ShoppingAgent = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    // 代理商商品
    this.getManagerList = function (keyWord,categoryId,pageIndex,success, error) {
        
        var url = ServiceBaseAddress + '/api/AgentCommodity/Organization';
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        else {
            url += '/0';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
      
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    },
    this.AgentCommodityDetail = function (commodityId, success, error) {
        var url = ServiceBaseAddress + '/api/AgentCommodity/' + commodityId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.Categories = function (success, error) {
        var url = ServiceBaseAddress + '/api/CommodityCategory/Manager';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

}

export { ShoppingAgent }
